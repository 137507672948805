<template>
  <div>
    <template v-if="user.is_student">
      <StudentCard :User="user"></StudentCard>
    </template>
    <template v-else>
      <UserCard :User="user"></UserCard>
    </template>
  </div>
</template>

<script>
// import * as names from "@/store/names";
import { mapGetters } from "vuex";

export default {
  name: "StudentCardView",
  components: {
    StudentCard: () => import("./StudentCardComponent"),
    UserCard: () => import("@/components/reusable/UserCard"),
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      user: "getUser",
    }),
  },
  methods: {},
  created() {
    this.$hasPermissions();
  },
};
</script>

<style>
</style>